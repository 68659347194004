import img0 from "./img0(연령대).png";
import img1 from "./img1(슈링크).png";
import img2 from "./img2(인지여부).png";
import img3 from "./img3(구매여부).png";
import img4 from "./img4(사용여부).png";

const experiences = [
  {
    img: img0,
    title: "연령대",
    answer: "응답 300개",
    iconBg: "#383E56",
    date: "2023 12/29 ~ 01/12",
    points: [
      "-----------------설명------------------",
      "---------------------------------------",
      "--------------------------------------",
    ],
  },
  {
    img: img1,
    title: "슈링크 플레이션(Shrink Flation)에 대해 알고 계신가요?",
    answer: "응답 300개",
    iconBg: "#383E56",
    date: "2023 12/29 ~ 01/12",
    points: [
      "-----------------설명------------------",
      "---------------------------------------",
      "--------------------------------------",
    ],
  },
  {
    img: img2,
    title: "슈링크플레이션이란  '줄어든다'는 뜻의 슈링크와 물가 상승을 의미하는 '인플레이션'의 합성어입니다. 상품의 크기나 양이 줄어들거나 때로는 품질이 재구성되거나 낮아지는 반면 가격은 동일하거나 증가하는 것을 말합니다. 아래의 사진은 슈링크 플레이션이 실제로 일어난 제품들의 예시입니다. 상품에서 슈링크 플레이션이 일어난 걸 알고계셨나요?",
    answer: "응답 300개",
    iconBg: "#383E56",
    date: "2023 12/29 ~ 01/12",
    points: [
      "-----------------설명------------------",
      "---------------------------------------",
      "--------------------------------------",
    ],
  },
  {
    img: img3,
    title: "평소에 구매하던 상품의 용량이 줄어든다면 구매 여부를 다시 고민하실 거 같나요?",
    answer: "응답 300개",
    iconBg: "#383E56",
    date: "2023 12/29 ~ 01/12",
    points: [
      "-----------------설명------------------",
      "---------------------------------------",
      "--------------------------------------",
    ],
  },
  {
    img: img4,
    title: "내가 구매하려는 상품의 슈링크플레이션 발생여부를 알려주는 서비스가 있다면 사용하실 건가요?",
    answer: "응답 300개",
    iconBg: "#383E56",
    date: "2023 12/29 ~ 01/12",
    points: [
      "-----------------설명------------------",
      "---------------------------------------",
      "--------------------------------------",
    ],
  },
];

export { experiences };
