import { Container } from "./styled";

const FavoritePage = () => {
  return (
    <Container>
      <h1>FavoritePage</h1>
    </Container>
  );
};

export default FavoritePage;
